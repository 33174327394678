import React, { createContext, useState, useMemo } from 'react'

const defaultProps = {
  open: true,
}
export const CTAContext = createContext(defaultProps)

export const CTAProvider = ({ children }) => {
  const [open, setOpen] = useState(defaultProps.open)

  const value = useMemo(() => ({ open, setOpen }), [open])
  return <CTAContext.Provider value={value}>{children}</CTAContext.Provider>
}
