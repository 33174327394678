import React, { useContext } from 'react'

import tw from 'twin.macro'
import { Link as AnchorLink } from 'react-scroll'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { CTAContext } from '../context/CTA'

const PageUpButton = () => {
  const { open } = useContext(CTAContext)
  return (
    <AnchorLink
      to="gatsby-focus-wrapper"
      smooth={true}
      duration={500}
      css={[
        tw`flex md:(hidden) z-50 fixed h-12 w-12 bg-white text-gray-900 rounded-full shadow-lg transform -translate-x-1/2 -translate-y-1/2 items-center justify-center cursor-pointer
              transition ease-in-out duration-150
              hover:(bg-yellow-600)
              focus:(bg-yellow-600 outline-none shadow-outline)`,
        open
          ? `right: -.5em; bottom: calc(5vh + 4.5em);`
          : `right: -.5em; bottom: calc(5vh + 1.5em);`,
      ]}
    >
      <FontAwesomeIcon icon={faChevronUp} size="1x" />
    </AnchorLink>
  )
}

export default PageUpButton
