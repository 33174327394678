import { useEffect, useState } from 'react'

const useMessageTimeout = ([message, setMessage], timeout = 3000) => {
  const [showMessage, setShowMessage] = useState(false)

  const countdown = setTimeout(() => {
    setShowMessage(false)
    setMessage('')
  }, timeout)

  useEffect(() => {
    if (message && message?.length > 0) {
      setShowMessage(true)
    }

    return () => clearTimeout(countdown)
  }, [message, timeout])

  return showMessage
}

export default useMessageTimeout
