import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'

export const A = styled.a`
  & ::after {
    content: '';
    width: 100%;
    height: 0.05em;
    margin-bottom: 0.5em;
    transition: 0.1s all linear;
    ${tw`bg-blue-400 absolute hidden`}
    ${({ underline }) => underline && tw`block`}
  }

  &:focus,
  &:hover {
    & ::after {
      height: 0.1em;
      margin-bottom: 0;
    }
  }

  ${tw`text-blue-700 cursor-pointer transition duration-100
        hover:(text-blue-400)
        focus:(text-blue-400)
        active:(text-blue-900)
    `}
  ${({ underline }) => underline && tw`inline-block relative`}
`
A.defaultProps = { underline: false }

export const ALink = A.withComponent(Link)
ALink.defaultProps = { underline: false }

export default A
