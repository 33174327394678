import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const HeaderContext = createContext(null)

const HeaderProvider = ({
  children,
  headerType,
  location,
  commercialPestControl,
}) => (
  <HeaderContext.Provider
    value={{
      headerType,
      isCommercial: headerType === 'commercial',
      isResidential: headerType === 'residential',
      location,
      commercialPestControl,
    }}
  >
    {children}
  </HeaderContext.Provider>
)

HeaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
  headerType: PropTypes.oneOf(['residential', 'commercial']),
  commercialPestControl: PropTypes.bool,
}

HeaderProvider.defaultProps = {
  headerType: 'residential',
  commercialPestControl: false,
}

const useHeaderContext = () => useContext(HeaderContext)

export { HeaderProvider as default, useHeaderContext, HeaderContext }
