export async function queryWordPress(query) {
  const res = await fetch(process.env.GATSBY_WP_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ query }),
  })

  if (!res.ok) {
    const message = `An error has occured: ${res.status}`
    throw new Error(message)
  }

  const json = await res.json()
  return json
}

export const submitForm = ({ id, formState }) =>
  fetch(`${process.env.GATSBY_FORM_SERVICE_ENDPOINT}/forms/${id}`, {
    method: 'post',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formState),
  }).then(res => res.json())

export const getZipFromCoords = ({ lat, lng }) => {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=postal_code&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`
  )
}

export const queryBranchByZipCode = zipCode =>
  queryWordPress(`{
    serviceAreas(where: {serviceAreaZip: "${zipCode}"}) {
        edges {
            node {
                title
                slug
                serviceAreaMeta {
                    serviceAreaBranch {
                        ... on Branch {
                            title
                            slug
                            branchTypes {
                                nodes {
                                    name
                                }
                            }
                            branchMeta {
                                branchOfficeAddress
                                branchOfficeCity
                                branchOfficeState
                                branchOfficeZip
                                branchOfficeEmailAddress
                                branchOfficePhone
                                branchOfficeHours
                            }
                        }
                    }
                }
            }
        }
    }
}`)

export const serviceBranchSearch = searchTerm =>
  queryWordPress(`{
    searchByZipCode: serviceAreas(where: {serviceAreaZip: "${searchTerm}"}) {
        edges {
            node {
                title
                slug
                serviceAreaMeta {
                    serviceAreaBranch {
                        ... on Branch {
                            title
                            slug
                            branchMeta {
                                branchOfficeAddress
                                branchOfficeCity
                                branchOfficeState
                                branchOfficeZip
                                branchOfficeEmailAddress
                                branchOfficePhone
                                branchOfficeHours
                            }
                        }
                    }
                }
            }
        }
    }
    searchByTerm: serviceAreas(where: {search: "${searchTerm}"}) {
        edges {
            node {
                title
                slug
                serviceAreaMeta {
                    serviceAreaBranch {
                        ... on Branch {
                            title
                            slug
                            branchMeta {
                                branchOfficeAddress
                                branchOfficeCity
                                branchOfficeState
                                branchOfficeZip
                                branchOfficeEmailAddress
                                branchOfficePhone
                                branchOfficeHours
                            }
                        }
                    }
                }
            }
        }
    }
}`).then(({ data }) => {
    // flaten the data into an array
    const searchByTerm = data?.searchByTerm?.edges
    const searchByZipCode = data?.searchByZipCode?.edges

    return [searchByTerm, searchByZipCode].flat()
  })
