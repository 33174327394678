import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo'

import '../styles/global.css'
import HeaderProvider from '../context/Header'
import { CTAProvider } from '../context/CTA'
import Header from './Header'
import Footer from './Footer'
import PageUpButton from './PageUpButton'
import tw from 'twin.macro'

import '@fontsource/fira-sans-extra-condensed/800.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import OnPageCTA from './OnPageCTA'

const useSeoQuery = () => {
  const data = useStaticQuery(graphql`
    query yoastSeoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)

  const stringified = JSON.stringify(data.wp.seo, (key, value) => {
    switch (key) {
      case 'siteUrl':
        return 'https://cookspest.com'
      case 'siteName':
        return value.replace('&039;', "'")
      default:
        return value
    }
  })

  const finalOutput = JSON.parse(stringified)

  return finalOutput
}

library.add(far, fab, fas)

const Layout = ({ children, headerType, location, commercialPestControl }) => {
  const yoastSeo = useSeoQuery()
  return (
    <div css={[tw`relative overflow-hidden`]}>
      <SEOContext.Provider value={{ global: yoastSeo }}>
        <CTAProvider>
          <HeaderProvider
            headerType={headerType}
            location={location}
            commercialPestControl={commercialPestControl}
          >
            <OnPageCTA />
            <Header />
          </HeaderProvider>

          <main id="main" aria-label="content">
            {children}
          </main>
          <PageUpButton />
          <Footer />
        </CTAProvider>
      </SEOContext.Provider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerType: PropTypes.oneOf(['residential', 'commercial']),
  commercialPestControl: PropTypes.bool,
}

Layout.defaultProps = {
  headerType: 'residential',
  commercialPestControl: false,
}

export default Layout
// uppercase
