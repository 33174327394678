import React, { useEffect, useState, useContext } from 'react'
import tw from 'twin.macro'
import { CTAContext } from '../context/CTA'
import { AiOutlineClose } from 'react-icons/ai'

const OnPageCTA = ({ location }) => {
  const [isMobile, setIsMobile] = useState(null)
  const [pageIsLoaded, setPageIsLoaded] = useState(false)
  const [CTA, setCTA] = useState(null)
  const { open, setOpen } = useContext(CTAContext)
  const [isSticky, setIsSticky] = useState(null)
  const [animateOut, setAnimateOut] = useState(false)
  const [isDismissed, setDismissed] = useState(null)
  const [hidden, setHidden] = useState(null)

  const CTAs = [
    {
      option: 1,
      type: 'call',
      text: 'Call us now!',
      CTA: 'Remove pests from your thoughts and home.',
      number: '800-239-9898',
    },
    {
      option: 2,
      type: 'contact',
      text:
        'Obtain a free pest control estimate adapted to your specific needs.',
      CTA: 'Reserve your consultation today.',
      link: '/residential/quote/',
    },
  ]

  const detectMobile = () => {
    const ismobile = window?.innerWidth < 768

    setIsMobile(ismobile)
  }
  const handleClose = () => {
    setAnimateOut(true)
    setTimeout(() => {
      setOpen(false)
      setAnimateOut(false)
    }, 300)
  }
  // choose alternating CTA that will support additional options
  useEffect(() => {
    const prevCTA = localStorage.getItem('CTA')
    const lastCTA = prevCTA == CTAs?.length
    detectMobile()
    const dismissed = sessionStorage.getItem('dismissed')
    if (dismissed) {
      setOpen(false)
      setDismissed(true)
    }

    if (!lastCTA && prevCTA) {
      setCTA(CTAs[prevCTA])
      localStorage.setItem('CTA', CTAs[prevCTA].option)
    } else {
      setCTA(CTAs[0])
      localStorage.setItem('CTA', CTAs[0].option)
    }
    setTimeout(() => {
      setPageIsLoaded(true)
    }, 2000)
  }, [])

  //hide CTA on commercial side of site

  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location?.pathname : null
    const dismissed = sessionStorage.getItem('dismissed')
    if (url && url.includes('commercial')) {
      setHidden(true)
    } else if (url && !url.includes('commercial') && !dismissed) {
      setHidden(false)
    }
  }, [location])

  useEffect(() => {
    if (!open) {
      sessionStorage.setItem('dismissed', true)
      setDismissed(true)
    }
  }, [open])

  useEffect(() => {
    // detect scroll height on load
    if (window?.pageYOffset > 590) {
      setIsSticky(true)
    }
    const onScroll = () => {
      // desktop styling for sticky
      if (window?.pageYOffset > 590 && !isMobile) {
        setIsSticky(true)
      } else if (window?.pageYOffset <= 590 && !isMobile) {
        setIsSticky(false)
      }
    }
    if (window && document && pageIsLoaded && open) {
      window.addEventListener('scroll', onScroll)
      window.addEventListener('resize', detectMobile)
    }
    return () => {
      pageIsLoaded && window.removeEventListener('scroll', onScroll)
      pageIsLoaded && window.removeEventListener('resize', detectMobile)
    }
  }, [pageIsLoaded, isMobile, open])

  return (
    open &&
    !hidden && (
      <div
        css={[
          tw`bg-blue-600  text-lg text-blue-50 font-semibold border-2 
        `,
        ]}
        className={
          'floating-div ' +
          (isMobile && ' fix-mobile ') +
          (isSticky && !isMobile && ' exit-right-enter-top fix ') +
          (!isMobile && !isSticky && ' enter-right ') +
          (animateOut && ' on-dismiss ')
        }
      >
        <div
          css={[tw`absolute`]}
          onClick={handleClose}
          className={
            'floating-close ' +
            (isSticky && !isMobile && ' fix exit-right-enter-top ') +
            (!isSticky && !isMobile && ' floating-close on-open ') +
            (isMobile && ' floating-close on-load ')
          }
        >
          <button
            css={[
              tw`relative bg-blue-600 text-blue-50  border-2 border-yellow-600 w-5 h-5 rounded-full cursor-pointer`,
            ]}
          >
            <p
              css={[
                tw`absolute z-100 font-light text-sm`,
                'top: -13px; left: 1px',
              ]}
            >
              <AiOutlineClose />
            </p>
          </button>
        </div>
        {CTA?.type === 'call' ? (
          <>
            <p css={[tw`leading-5`]}>
              {CTA?.text}
              <br></br>
              {CTA?.CTA}
            </p>
            <p css={[tw`leading-5`]}>
              {' '}
              <a
                href={`tel:8002399898`}
                tw="text-yellow-600 font-bold border-b-2  border-yellow-600"
              >
                {CTA?.number}
              </a>
            </p>
          </>
        ) : CTA?.type === 'contact' ? (
          <>
            <p css={[tw`leading-5`]}>{CTA?.text}</p>
            <p css={[tw`leading-5`]}>
              <a
                href={CTA?.link}
                tw="text-yellow-600 font-bold border-b-2 border-yellow-600 "
              >
                {CTA?.CTA}
              </a>
            </p>
          </>
        ) : null}
      </div>
    )
  )
}

export default OnPageCTA
