import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import tw from 'twin.macro'

import Container from './Container'
import A, { ALink } from './A'

import { flatListToHierarchical } from '../helpers'
import Logos from './Logos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FooterSocial = () => {
  const channels = [
    {
      label: 'Facebook',
      url: 'https://www.facebook.com/CooksPestControlInc',
      icon: 'facebook-f',
    },
    {
      label: 'Instagram',
      url: 'https://www.instagram.com/cookspestcontrol/',
      icon: 'instagram',
    },
    { label: 'Twitter', url: 'https://twitter.com/cookspest', icon: 'twitter' },
    {
      label: 'YouTube',
      url: 'https://www.youtube.com/channel/UCTkm5t5_6tXjzkv2YDrLvzg',
      icon: 'youtube',
    },
  ]

  return (
    <nav aria-label="Social Media" tw="mb-8">
      {channels.map((channel, i) => (
        <a
          key={channel.label}
          aria-label={`Follow us on ${channel.label}`}
          target="_blank"
          rel="noopener noreferrer"
          href={channel.url}
          css={[
            tw`inline-flex mr-3 items-center justify-center w-12 h-12 rounded bg-blue-600 text-white hocus:(transform -translate-y-2) transition ease-in-out duration-150`,
            i === channels.length && tw`mr-0`,
          ]}
        >
          <span tw="sr-only">
            Follow Cook's Pest Control on {channel.label}
          </span>
          <FontAwesomeIcon
            icon={['fab', channel.icon]}
            size="2x"
            aria-role="presentation"
            alt=""
          />
        </a>
      ))}
    </nav>
  )
}

const FooterNavigation = () => {
  const {
    wpMenu: { menuItems },
  } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "footer" }) {
        menuItems {
          nodes {
            key: id
            parentId
            label
            description
            path
          }
        }
      }
    }
  `)

  const navItems = flatListToHierarchical(menuItems.nodes)

  const NavItemList = ({ items }) => (
    <ul>
      {items.map(child => (
        <li key={`${child.label}-nav-item-list-li`}>
          <A
            underline
            key={`${child.label}-nav-item-list`}
            tw="inline-block mb-4 border-b border-transparent hocus:(border-blue-800 text-blue-800)"
            href={child.path || '#'}
          >
            {child.description ? (
              <FontAwesomeIcon icon={['fas', child.description]} tw="mr-2" />
            ) : null}
            {child.label}
          </A>
        </li>
      ))}
    </ul>
  )

  return (
    <nav
      aria-label="Footer"
      tw="sm:(px-4) mb-8 grid grid-cols-4 lg:(grid-cols-8 col-gap-10 px-20) xl:(col-gap-16)"
    >
      {navItems.map((item, index) => {
        let children = item.children
        const numCols = Math.round(item.children.length / 5)

        if (item.children.length > 5) {
          children = []

          for (let i = 0; i < numCols; i++) {
            children.push(item.children.slice(i * 5, i * 5 + 5))
          }
        }

        return (
          <div
            key={`nav-wrapper-${index}`}
            css={[
              tw`text-left mb-8 lg:(mb-0) col-span-2`,
              //   `grid-column: span ${numCols} / span ${numCols};`,
            ]}
          >
            <strong tw="block text-lg font-bold mb-4">{item.label}</strong>
            {Array.isArray(children[0]) ? (
              <div
                css={[
                  tw`grid col-gap-10 xl:(col-gap-16)`,
                  `grid-template-columns: repeat(${numCols}, minmax(0, 1fr))`,
                ]}
              >
                {children.map(column => (
                  <NavItemList
                    key={`${item.label}-nav-item-list`}
                    items={column}
                  />
                ))}
              </div>
            ) : (
              <NavItemList
                key={`${item.label}-nav-item-list`}
                items={children}
              />
            )}
          </div>
        )
      })}
    </nav>
  )
}

const Footer = () => {
  return (
    <footer aria-label="Site Footer" tw="bg-gray-50 mt-8 pb-32 md:(mt-20)">
      <Container>
        <div tw="pt-12 text-center sm:(ml-2 mr-2)">
          <Logos.WithCookie tw="w-32 mx-auto mb-6" />
          <p tw="text-xs lg:(text-lg w-5/12) text-gray-700 mx-auto mb-12 px-4">
            The South’s Most Trusted Name in Pest Control.
          </p>
          <FooterNavigation />
          <FooterSocial />
          <p tw="text-sm md:(text-lg) text-gray-700">
            © {new Date().getFullYear()} Cook’s Pest Control | All rights
            reserved.
          </p>
          <p tw="text-xs text-gray-700">
            <ALink
              tw="text-gray-700 border-b border-gray-700 hocus:(border-blue-800 text-blue-800)"
              to="/privacy-policy/"
            >
              Privacy Policy
            </ALink>{' '}
            |{' '}
            <ALink
              tw="text-gray-700 border-b border-gray-700  hocus:(border-blue-800 text-blue-800)"
              to="/terms-of-use/"
            >
              Terms Of Use
            </ALink>{' '}
            |{' '}
            <ALink
              tw="text-gray-700 border-b border-gray-700  hocus:(border-blue-800 text-blue-800)"
              to="/accessibility-statement/"
            >
              Accessibility Statement
            </ALink>
          </p>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
