import React, { useState } from 'react'
import tw from 'twin.macro'

import Navbar from './Navbar'
import Logos from './Logos'
import { LocationFinder } from './LocationFinder'
import { Link } from 'gatsby'
import { ALink } from './A'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHeaderContext } from '../context/Header'

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const toggleNavbar = () => setNavbarOpen(!navbarOpen)
  const { isCommercial } = useHeaderContext()
  const { isResidential } = useHeaderContext()

  return (
    <div css={[tw`relative z-100`]}>
      {isCommercial && (
        <div aria-role="announcement" tw="w-full sticky">
          <div tw="w-full grid grid-cols-2 text-center p-0 lg:(hidden)">
            <div tw="bg-gray-100 m-0 py-2 text-xs">
              <ALink tw="text-gray-700" to="/">
                Residential Pest Control
              </ALink>
            </div>
            <div tw="text-white bg-yellow-600 m-0 py-2 font-semibold text-xs">
              <ALink to="/commercial/">Commercial Pest Control</ALink>
            </div>
          </div>
          <div tw="hidden bg-blue-100 text-center lg:(block)">
            <p tw="text-blue-800 m-0 py-2 text-sm">
              You are currently in Commercial Pest Control,{' '}
              <ALink underline to="/">
                Click Here<span tw="sr-only">Residential Pest Control</span>
              </ALink>{' '}
              for Residential Pest Control.
            </p>
          </div>
        </div>
      )}
      {isResidential && (
        <div aria-role="announcement" tw="w-full sticky">
          <div tw="w-full grid grid-cols-2 text-center p-0 lg:(hidden)">
            <div tw="text-white bg-blue-600 m-0 py-2 text-xs font-semibold">
              <ALink tw="text-white" to="/">
                Residential Pest Control
              </ALink>
            </div>
            <div tw="bg-gray-100 m-0 py-2 text-xs">
              <ALink tw="text-gray-700" to="/commercial/">
                Commercial Pest Control
              </ALink>
            </div>
          </div>
        </div>
      )}
      <header
        css={[
          `background: linear-gradient(to right, white 50%, ${
            isCommercial ? '#ffd100' : '#194798'
          } 50%); z-index: 100;`,
          tw`shadow-md relative`,
        ]}
        aria-label="Site Header"
      >
        <div
          tw="mx-auto bg-white flex
                flex-col w-full
                lg:(flex-row justify-between items-center items-stretch)
                xl:(max-w-screen-xl)"
        >
          <div tw="py-4 xl:(pl-0) flex items-center justify-center relative">
            <Link
              to="/"
              alt="Cook’s Pest Control, select to go home"
              aria-label="Cook's Pest Control, select to go home"
            >
              <span tw="sr-only">Cook's Pest Control</span>
              <Logos.TextOnly tw="h-10 w-auto sm:(ml-4)" />
              <span css={[tw`text-sm sm:(ml-4)`, !isCommercial && tw`hidden`]}>
                Commercial Services
              </span>
            </Link>
            <LocationFinder tw="border-l-2 pl-4 ml-4 xl:(pl-6 ml-6) hidden md:(flex)" />
            <button
              tw="block absolute right-0 w-16 text-center py-2 text-yellow-600 lg:(hidden)"
              onClick={toggleNavbar}
            >
              <span tw="sr-only">Toggle the navigation menu</span>
              <FontAwesomeIcon icon={navbarOpen ? faTimes : faBars} size="2x" />
            </button>
          </div>
          <Navbar open={navbarOpen} toggleOpen={toggleNavbar} />
        </div>
      </header>
    </div>
  )
}

export default Header
