import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import PropTypes from 'prop-types'

export const Button = styled.button(
  ({ primary, secondary, pill, outline, large, small, disabled, link }) => [
    tw`inline-block rounded px-6 py-3 text-lg md:(px-4 py-2 text-base) shadow-sm
    transition ease-in duration-100
    hover:(shadow-lg)
    focus:(outline-none shadow-outline)
    active:(shadow)`,

    primary &&
      tw`
        text-gray-800 bg-yellow-600 shadow-sm
        hover:(bg-yellow-500)
        focus:(bg-yellow-500 border-yellow-500)
        active:(bg-blue-600 text-white)`,
    secondary &&
      tw`
        bg-gray-200
        hover:(bg-gray-300)
        focus:(bg-gray-300 border-gray-300)
        active:(bg-blue-600 text-white)`,

    pill && tw`rounded-full px-6`,

    outline && tw`border bg-transparent shadow-none hover:shadow-none`,
    outline &&
      primary &&
      tw`
        border-yellow-600 text-yellow-600
        hover:(bg-yellow-600 text-white)
        focus:(bg-yellow-600 text-white)
        active:(bg-yellow-500)`,
    outline &&
      secondary &&
      tw`
        border-gray-600 text-gray-600 
        hover:(bg-gray-600 text-white)
        focus:(bg-gray-600 text-white)
        active:(bg-gray-500)`,

    large && tw`rounded-lg md:(px-6 py-3 text-lg)`,
    small && tw`px-2 py-1 text-sm`,

    disabled && tw`opacity-25 pointer-events-none`,

    link &&
      tw`
        bg-transparent shadow-none
        hover:(bg-transparent shadow-none underline text-yellow-600)
        focus:(bg-transparent shadow-none underline text-yellow-600)
        active:(bg-transparent shadow-none underline text-yellow-600)`,
  ]
)
Button.defaultProps = { primary: true }

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  pill: PropTypes.bool,
  outline: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  link: PropTypes.bool,
}

export const ButtonLink = Button.withComponent(Link)
ButtonLink.defaultProps = { primary: true }

export const ButtonA = Button.withComponent('a')
ButtonA.defaultProps = { primary: true }

export default Button
